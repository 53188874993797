import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import convertToHTML from "markdown-to-html-converter"
import { Link } from "gatsby"
import slugify from "slugify"
import { navigate } from "@reach/router"
import Img from "gatsby-image"
import SEO from "src/components/global/SEO.js"
import noHangers from "src/components/global/fn/noHangers.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import typographySizes from "src/assets/styles/typographySizes.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import colors from "src/assets/styles/colors.js"
import Cookies from "src/components/global/Cookies.js"

import BigText from "src/components/global/typography/BigText"
import MidText from "src/components/global/typography/MidText"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import BodyText from "src/components/global/typography/BodyText.js"
import ReactMarkdown from "react-markdown"
import CompetencesList from "src/components/global/competences/CompetencesList.js"

import InformationSection from "src/components/Project/InformationSection.js"
import Wrapper from "src/components/global/Wrapper"
import NewBusinessSection from "src/components/global/NewBusinessSection.js"
import Slider from "src/components/global/Slider"
import SliderChangeImg from "src/components/global/SliderChangeImgGit"

import NumbersSection from "src/components/Project/NumbersSection.js"
import ProjectCard from "src/components/whatWeDo/ProjectCard.js"
import NewBtn from "src/components/global/btn/NewBtn.js"
import MainBtn from "src/components/global/btn/MainBtn"

import Projects from "src/components/global/Projects.js"

const { htmlToText } = require("html-to-text")

const goBack = () => {
  navigate(-1)
}

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`

const BackLink = styled(Link)`
  font-size: ${typographySizes.s}px;
  text-decoration: none;
  cursor: pointer;

  margin-bottom: 15px;
  @media (max-width: ${mediaQuery.laptop}) {
    order: -1;
    margin-top: 0px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    order: -1;
    margin: 50px 0 30px 0;
  }
`

const HeaderTitle = styled(BigText)`
  width: 50%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const CompetanceWrapper = styled.div`
  display: flex;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-top: 10px;
  }
`

const Competance = styled.p`
  font-size: ${typographySizes.s}px;
  margin-right: 20px;
`

const HeaderCredits = styled.p`
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGray};
  padding: 5px;
  background-color: ${colors.white};
  position: absolute;
  left: 5px;
  bottom: 0;
  opacity: 0;

  transform: translate(0, 100%);
  transition: all 0.5s;
  @media (max-width: ${mediaQuery.tablet}) {
    position: relative;
    opacity: 1;
    padding: 0px;
    left: 0px;
  }
`

const Separator = styled.hr`
  width: 100%;
  height: 2px;
  background-color: ${colors.darkGrey};
  border: none;
`

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`

const Subtitle = styled(MidText)`
  max-width: 540px;
  width: calc(50% - 60px);
  @media (max-width: ${mediaQuery.tablet}) {
    max-width: 100%;
    margin-bottom: 20px;
    width: 100%;
  }
`

const RightInfo = styled.div`
  width: calc(50% - 60px);
  margin-right: 60px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-right: 0px;
  }
`

const InfoText = styled(BodyText)`
  margin-bottom: 100px;
  .marginP {
    p {
      margin-bottom: 20px;
    }
    p:last-of-type {
      margin-bottom: 0px;
    }
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`

const ListOfServices = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
`

const ListOfServicesItem = styled.div`
  width: 41.66%;
  margin-right: 8.33%;
  margin-top: 80px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-right: 0px;
    margin-top: 40px;
  }
`

const Number = styled.span`
  font-size: ${typographySizes.l}px;
  color: ${colors.darkGrey};
  line-height: 1.2;
  margin-right: 40px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.ml}px;
    line-height: 1.3;
    margin-right: 20px;
  }
`

const ListOfServicesItemTitle = styled(MidText)`
  margin-bottom: 10px;
`

const ListOfServicesItemDescription = styled(BodyText)`
  padding-top: 10px;
  border-top: 2px solid ${colors.darkGrey};
`

const CompetencesLayout = ({ data, pageContext }) => {
  // const htmlStrTitle = convertToHTML(data.strapiProjects.First_Paragraph)
  // const htmlTextTittle = htmlToText(htmlStrTitle, {
  //   wordwrap: 130,
  // })
  // let seoTitle = htmlTextTittle.replace(/ *\[[^)]*\] */g, " ")

  // const htmlStrDescription = convertToHTML(data.strapiProjects.Second_Paragraph)
  // const htmlTextDescription = htmlToText(htmlStrDescription, {
  //   wordwrap: 160,
  // })
  // let seoDescription = htmlTextDescription.replace(/ *\[[^)]*\] */g, " ")

  return (
    <>
      <Cookies />
      <SEO
        title={data.strapiCompetences.Name}
        description={data.strapiCompetences.Subtitle}
        // image={data.strapiProjects.Thumbnail.localFile.publicURL}
      />

      <Navigation
        lang={pageContext.locale}
        langUrl={pageContext.langUrl}
        navBtnLink={data.strapiGlobal.Nav_btn_link}
        navBtnText={data.strapiGlobal.Nav_btn_text}
        titleLang={
          data.titleLang &&
          slugify(data.titleLang?.Name, { lower: true, strict: true })
        }
      />

      <Wrapper>
        <TopWrapper>
          <BackLink
            to={`/${
              pageContext.locale === "en" ? "en/what-we-do" : "corobimy"
            }`}
          >
            {pageContext.locale === "en"
              ? "← back to list of services"
              : "← powrót do listy usług"}
          </BackLink>
          <HeaderTitle>{data.strapiCompetences.Name}</HeaderTitle>
        </TopWrapper>
        <Separator />
        {(data.strapiCompetences.Subtitle ||
          data.strapiCompetences.Top_paragraph) && (
          <>
            <InfoWrapper>
              <Subtitle>{data.strapiCompetences.Subtitle}</Subtitle>
              <RightInfo>
                {data.strapiCompetences.Top_paragraph && (
                  <InfoText>
                    <ReactMarkdown
                      className={"marginP"}
                      source={noHangers(data.strapiCompetences.Top_paragraph)}
                    />
                  </InfoText>
                )}
                <MainBtn
                  hoverText={data.strapiCompetences.Btn.Hover}
                  Mobile={data.strapiCompetences.Btn.Mobile}
                  link={`/${
                    pageContext.locale === "en" ? "en/contact" : "kontakt"
                  }`}
                >
                  {data.strapiCompetences.Btn.Main}
                </MainBtn>
              </RightInfo>
            </InfoWrapper>
            {/* <Separator /> */}
          </>
        )}

        <ListOfServices>
          {data.strapiCompetences.List_of_services.map((item, index) => (
            <ListOfServicesItem key={index}>
              <ListOfServicesItemTitle>
                <Number>0{index + 1}</Number>
                {item.Name}
              </ListOfServicesItemTitle>
              <ListOfServicesItemDescription>
                {item.Description}
              </ListOfServicesItemDescription>
            </ListOfServicesItem>
          ))}
        </ListOfServices>
      </Wrapper>

      <Projects
        title={
          pageContext.locale !== "en"
            ? `przykładowe projekty: ${data.strapiCompetences.Name}`
            : `example projects: ${data.strapiCompetences.Name}`
        }
        projects={data.allStrapiProjects.edges}
        locale={pageContext.locale}
      />

      <Wrapper>
        <SmallTitle>
          {" "}
          {pageContext.locale !== "en"
            ? "nasze pozostałe usługi:"
            : "our other services:"}
        </SmallTitle>
        <CompetencesList
          lang={pageContext.locale}
          competences={data.allStrapiCompetences.edges}
        />
      </Wrapper>

      {console.log(data.allStrapiProjects.edges)}

      <Footer lang={pageContext.locale} companyData={data.strapiContactPageN} />
    </>
  )
}
export const query = graphql`
  query CompetencesLayout(
    $id: String!
    $idLang: Int!
    $locale: String!
    $projectsHighlighted: [String]
  ) {
    strapiGlobal(locale: { eq: $locale }) {
      Nav_btn_link
      Nav_btn_text
    }
    allStrapiProjects(
      filter: { Title: { in: $projectsHighlighted }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          Title
          Thumbnail {
            localFile {
              childImageSharp {
                fluid(traceSVG: { background: "#ffffff", color: "#ffff00" }) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          competences {
            Name
          }
        }
      }
    }

    titleLang: strapiCompetences(strapiId: { eq: $idLang }) {
      Name
    }
    strapiCompetences(id: { eq: $id }) {
      Name
      Subtitle
      Top_paragraph
      Btn {
        Hover
        Main
        Mobile
      }
      List_of_services {
        Description
        Name
      }
    }
    allStrapiCompetences(
      filter: { locale: { eq: $locale }, id: { ne: $id } }
      sort: { order: ASC, fields: Order }
    ) {
      edges {
        node {
          Name
          strapiId
        }
      }
    }
    strapiContactPageN(locale: { eq: $locale }) {
      New_business_Claim
      New_business_Title
      About_Btn {
        Hover
        Main
        Mobile
      }
      Brief_btn {
        Hover
        Main
        Mobile
        Link
      }
    }
    strapiContactPageN(locale: { eq: $locale }) {
      Company_details_Address
      Company_details_Krs
      Company_details_Name
      Company_details_Nip
      Company_details_Regon
      Company_details_Title
    }
  }
`

export default CompetencesLayout
